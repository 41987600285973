jQuery(function($) {
	
	var Vaks_Scripts = {

		debounce: function(func, wait, immediate) {
			let timeout;
			return function executedFunction() {
				const context = this;
				const args = arguments;
				const later = function() {
					timeout = null;
					if (!immediate) func.apply(context, args);
				};
				const callNow = immediate && !timeout;
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if (callNow) func.apply(context, args);
			};
		},

		headerScripts: function() {
			$('.burger-btn').click(function () {
				$('body').toggleClass('menu-open');
				$(this).toggleClass('active');
			});
			// Header Search
			let searchForm = $('.header__search-wrap');
			$('.header__search-btn').click(function(){
				$('body').addClass('search-open');
			});
			$('.header__search-close').click(function(){
				$('body').removeClass('search-open');
			});
			// Vision
			let accessCount = 0;
			var accessCookie = $.cookie('access');
			if (accessCookie && accessCookie != 0) {
				$("body").addClass('access-'+(accessCookie));
				accessCount = accessCookie;
			}
			$('.vision-item').click(function(){
				event.preventDefault();
				$('.pojo-a11y-toolbar-link[data-action="'+$(this).data('action')+'"]').trigger('click');
				if ($(this).data('action') === 'reset') {
					$('body').removeClass('access-'+(accessCount));
					$.cookie('access', 0, {path: '/'});
					accessCount = 0;
				}
			});
			$('.vision-item-resize').click(function(){
				event.preventDefault();
				if ($(this).data('action') === 'resize-plus' && accessCount <= 1) {
					$('body').removeClass('access-'+(accessCount))
					$('body').addClass('access-'+(++accessCount));
					$.cookie('access', accessCount, {path: '/'});
				}
				if ($(this).data('action') === 'resize-minus' && accessCount >= 1) {
					$('body').removeClass('access-'+(accessCount));
					$.cookie('access', 0, {path: '/'});
					accessCount--;
					if (accessCount != 0) {
						$('body').addClass('access-'+(accessCount));
						$.cookie('access', accessCount, {path: '/'});
					}
				}
			});
		},

		accordion: function() {
			let block = $('.accordion-block');
			let list = $('.accordion-content');

			block.click( function() {
				if ($(this).hasClass('active')) {
					$(this).removeClass('active');
					list.slideUp( 250 );
				} else {
					block.removeClass('active');
					list.slideUp( 250 );
					$(this).addClass('active');
					$(this).next().slideToggle( 250 );
				}
			});
		},

		multiAccordion: function() {
			let block1 = $('.accordion-multi-block-1');
			let block2 = $('.accordion-multi-block-2');
			let block3 = $('.accordion-multi-block-3');
			let list1 = $('.accordion-multi-list-1');
			let list2 = $('.accordion-multi-list-2');
			let list3 = $('.accordion-multi-list-3');

			block1.click( function() {
				list1.slideUp( 250 );
				list2.slideUp( 250 );
				list3.slideUp( 250 );
				block2.removeClass('active');
				block3.removeClass('active');
				if ($(this).hasClass('active')) {
					$(this).removeClass('active');
				} else {
					block1.removeClass('active');
					$(this).addClass('active');
					$(this).next().slideToggle( 250 );
				}
			});

			block2.click( function() {
				list2.slideUp( 250 );
				list3.slideUp( 250 );
				block3.removeClass('active');
				if ($(this).hasClass('active')) {
					$(this).removeClass('active');
				} else {
					block2.removeClass('active');
					$(this).addClass('active');
					$(this).next().slideToggle( 250 );
					$(this).closest(list1).slideDown( 250 );
				}
			});

			block3.click( function() {
				list3.slideUp( 250 );
				if ($(this).hasClass('active')) {
					$(this).removeClass('active');
				} else {
					block3.removeClass('active');
					$(this).addClass('active');
					$(this).next().slideToggle( 250 );
					$(this).closest(list1).slideDown( 250 );
					$(this).closest(list2).slideDown( 250 );
				}
			});
		},

		tabs: function() {
			$('.tab').click(function(){
				$('.tab').removeClass('active');
				$(this).addClass('active');
				let tabIndex = $(this).data('tab');
				$('.tab-content').addClass('hide');
				$('.tab-content--'+tabIndex).removeClass('hide');

				if ($(this).data('slug')) {
					history.pushState('','', window.location.origin + window.location.pathname + '?results=' + $(this).data('slug'));
				}
				Vaks_Scripts.contactsFormRedirect();
			});
		},

		contactsFormRedirect: function() {
			let contactsForm = $('#contacts-form');
			if (contactsForm.length) {
				if ($('#contacts-form-pi').hasClass('hide')) {
					wpcf7.api.root = 'https://ap.vaks.gov.ua/wp-json/';
				} else {
					wpcf7.api.root = 'https://first.vaks.gov.ua/wp-json/';
				}
			}
		},

		formSubmit: function() {
			let wpcf7Elm = document.querySelectorAll('.wpcf7');
			let access = $('.form-access');
			if (wpcf7Elm) {

				let fileInput = $('.file-field');
				let fileBtn = $('.file-btn');
				let fileBtnVal = $('.file-btn').html();
				if (fileBtn.length) {
					fileBtn.click(function() {
						fileInput.click();
					});
					fileInput.change(function() {
						if ($(this).val()) {
							fileBtn.html($(this).val().replace(/C:\\fakepath\\/i, ''));
						} else {
							fileBtn.html(fileBtnVal);
						}
					});
				}
				
				let date = Vaks_Scripts.dateNow();
				$('.contacts-form__date span').html(date);
				$('.hidden-field-date').val(date);

				$('.form-access__close').click(function(){
					access.removeClass('active');
				});
				wpcf7Elm.forEach(function (elem) {
					elem.addEventListener('wpcf7mailsent', function (event) {
						access.addClass('active');
						setTimeout(function () {
							access.removeClass('active');
						}, 5000);
					}, false);
				});
				$('.wpcf7-form').on( 'input', '.wpcf7-not-valid', function( ) {
					$(this).removeClass('wpcf7-not-valid');
				});
			}
		},

		dateNow: function() {
			let d = new Date();
			let month = d.getMonth()+1;
			let day = d.getDate();

			let output = day + '.' + month + '.' + d.getFullYear();

			return	output;
		},

		filters: function() {
			var filterType = $('.filter-types__item');
			filterType.click(function(){
				filterType.removeClass('active');
				$(this).addClass('active');
				Vaks_Scripts.getFilterPosts();
			});

			var filterSearch = $('#number-search');
			if (filterSearch.length) {
				filterSearch.keyup( Vaks_Scripts.debounce( function(event) {
					if (event.keyCode != 13) {
				    	Vaks_Scripts.getFilterPosts();
						return false;
					}
				}, 1000));
				filterSearch.keydown(function(event) {
					if (event.keyCode == 13) {
						Vaks_Scripts.getFilterPosts();
						return false;
					}
				});
			}
		},

		multiSelectFilters: function() {
			var filter = $('.multi-select');
			var btn = $('.multi-select__choice');
			var bottom = $('.multi-select__bottom');
			var reset = $('.multi-select__reset');

			if (btn.length) {
				btn.click(function(){
					$(document).off('click.filterOpen');
					filter.toggleClass('filter-open');
					$(document).on('click.filterOpen', function (e) {
						if ( !btn.is(e.target) && btn.has(e.target).length === 0 && !bottom.is(e.target) && bottom.has(e.target).length === 0 && !reset.is(e.target) && reset.has(e.target).length === 0) {
							filter.removeClass('filter-open');
							setTimeout(function(){
								$(document).off('click.filterOpen');
							}, 500);	
						};
					});
				});
			}

			$('.multi-select__item').click(function(){
				$(this).toggleClass('active');
				Vaks_Scripts.getFilterPosts();
			});

			$('.multi-select__reset').click(function(){
				$('.multi-select__item').removeClass('active');
				Vaks_Scripts.getFilterPosts();
			});
		},

		getFilterParams: function() {
			var filterParam = [];

			filterParam['authors'] = [];
			$('.multi-select__item.active').each(function() {
				filterParam['authors'].push($(this).data('author'));
			});

			let searchParams = $('#number-search');
			if (searchParams.length) {
				filterParam['search'] = '';
				filterParam['search'] = searchParams.val();
			}

			filterParam['type'] = [];
			filterParam['type'] = $('.filter-types__item.active').data('type');

			return filterParam;
		},

		getFilterPosts: function() {
			var parameters = '';
			var	postType = $('.filters-section').data('type');
			var filterParams = Vaks_Scripts.getFilterParams();
			var l = '';

			if (lang != 'uk') {
				l = '/' + lang;
			}

			if (filterParams['type'] && filterParams['type'] != 'all') {
				if (postType == 'publications') {
					parameters += '&material-type=' + filterParams['type'];
				} else if (postType == 'gallery') {
					parameters += '&gallery-type=' + filterParams['type'];
				}
			}

			if (filterParams['search']) {
				parameters += '&number=' + filterParams['search'];
			}

			if (filterParams['authors'].length > 0) {
				parameters += '&authors=' + filterParams['authors'];
				$('.multi-select__count').html(filterParams['authors'].length).show();
				$('.multi-select__reset').show();
			} else {
				$('.multi-select__count').hide();
				$('.multi-select__reset').hide();
			}

			if (parameters.length) {
				parameters = '?'+ parameters.slice(1);
			}

			history.pushState('','', window.location.origin + l + '/' + postType + '/' + parameters);

			var data = {
				'action'	: 'get_filter_posts',
				'postType'	: postType,
				'types'		: filterParams['type'],
				'search'	: filterParams['search'],
				'authors'	: filterParams['authors'],
				'urlParam'	: parameters
			};

			$.ajax({
				url:ajaxurl,
				data:data,
				type:'POST',
				success:function(data){
					let res = JSON.parse(data);
					if (res.posts !== ''){
						$('.publications-list, .announcements-list, .articles-list, .gallery-list').html(res.posts);
					}
					$('.pagination').html(res.pagination);
				}
			});
		},

		callsToCourtFilters: function() {
			let callsFilter = $('#calls-number-search, #calls-name-search');
			if (callsFilter.length ) {
				callsFilter.keyup( Vaks_Scripts.debounce( function(event) {
					if (event.keyCode != 13) {
						Vaks_Scripts.getCallsToCourt();
						return false;
					}
				}, 1000));
				callsFilter.keydown(function(event) {
					if (event.keyCode == 13) {
						Vaks_Scripts.getCallsToCourt();
						return false;
					}
				});
			}
		},

		getCallsToCourt: function() {
			let numberSearch = $('#calls-number-search').val();
			let nameSearch = $('#calls-name-search').val();
			
			var data = {
				'action'	: 'get_calls_to_court',
				'number'	: numberSearch,
				'name'		: nameSearch,
				'pageId'	: $('.calls-section').data('page')
			};
			$.ajax({
				url:ajaxurl,
				data:data,
				type:'POST',
				success:function(data){
					let res = JSON.parse(data);
					if (res.calls !== ''){
						$('.calls-section__wrap').html(res.calls);
					}
					Vaks_Scripts.accordion();
				}
			});
		},

		newsSlider: function() {
			var publicationsSlider = jQuery('.publications-slider');
			if (publicationsSlider.length && window.innerWidth < 1024 && window.innerWidth > 767) {
				publicationsSlider.not('.slick-initialized').slick({
					slidesToShow: 2,
					infinite: false,
					appendArrows: '.publications-slider-nav',
					prevArrow: '<div class="publications-slider-nav__item"><svg><use xlink:href="#chevron-slider-left"></use></svg></div>',
					nextArrow: '<div class="publications-slider-nav__item"><svg><use xlink:href="#chevron-slider-right"></use></svg></div>',
				});
			} else if ((publicationsSlider.hasClass('slick-initialized')) && (window.innerWidth > 1023 || window.innerWidth < 768)) {
				publicationsSlider.slick( 'unslick' );
			}
		},

		gallery: function() {
			var gallerySliderTop = jQuery('.gallery-slider-top');
			if (gallerySliderTop.length) {
				gallerySliderTop.slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: false,
					appendArrows: '.gallery-top-nav',
					prevArrow: '<div class="gallery-top-nav__item gallery-top-nav__item--prev"><svg><use xlink:href="#arrow-left"></use></svg></div>',
					nextArrow: '<div class="gallery-top-nav__item gallery-top-nav__item--next"><svg><use xlink:href="#arrow-right"></use></svg></div>',
				});
				lightGallery(document.getElementById('gallery-slider-top'), {
					selector: '.slick-slide:not(.slick-cloned).gallery-slider-top__item'
				}); 
			}
			var gallerySliderBottom = jQuery('.gallery-slider-bottom');
			if (gallerySliderBottom.length) {
				gallerySliderBottom
				.on('init', function(event, slick) {
					$('.gallery-slider-bottom .slick-slide.slick-current').addClass('is-active');
				})
				.slick({
					slidesToShow: 4,
					slidesToScroll: 1,
					arrows: false,
					infinite: false,
					focusOnSelect: true,
					responsive: [
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 3,
						}
					}
					]
				});
			}
			gallerySliderTop.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				gallerySliderBottom.slick('slickGoTo', nextSlide);
				var nextNavSlideElem = '.gallery-slider-bottom .slick-slide[data-slick-index="' + nextSlide + '"]';
				$('.gallery-slider-bottom .slick-slide.is-active').removeClass('is-active');
 				$(nextNavSlideElem).addClass('is-active');
			});
			gallerySliderBottom.on('click', '.slick-slide', function(event) {
			 	event.preventDefault();
			 	var goToSingleSlide = $(this).data('slick-index');
			 	gallerySliderTop.slick('slickGoTo', goToSingleSlide);
			 });
		},

		docsMore: function() {
			let btn = $('.org-doc-section__btn');

			btn.click( function() {
				if ($(this).hasClass('active')) {
					$(this).removeClass('active');
					$(this).siblings('.docs-list').addClass('list-close');
				} else {
					$(this).addClass('active');
					$(this).siblings('.docs-list').removeClass('list-close');
				}
			});
		},

		corruptionFormOpen: function() {
			let btn = $('.preventing-corruption-open-form__btn');
			let form = $('.contacts-form-section--corruption');

			btn.click( function() {
				if ($(this).hasClass('active')) {
					$(this).removeClass('active');
					form.removeClass('form-open');
				} else {
					$(this).addClass('active');
					form.addClass('form-open');
				}
			});
		},

		sitemap: function() {
			let blog = $('.sitemap-section__blog');
			let btn = $('.sitemap-section__blog-title');
			let list = $('.sitemap-section__menu');

			btn.click( function() {
				if ($(this).hasClass('active')) {
					$(this).removeClass('active');
					list.removeClass('show');
					blog.removeClass('open');
				} else {
					btn.removeClass('active');
					list.removeClass('show');
					blog.removeClass('open');
					$(this).addClass('active');
					$(this).siblings(list).addClass('show');
					$(this).closest(blog).addClass('open');
				}
			});
		},

		valid: function() {
			var invalidClassName = 'invalid'
			var inputs = document.querySelectorAll('.main-subscription__input')
			inputs.forEach(function (input) {
				input.addEventListener('invalid', function () {
					input.classList.add(invalidClassName)
				})
				input.addEventListener('input', function () {
					if (input.validity.valid) {
						input.classList.remove(invalidClassName)
					}
				})
			})
		},

		init: function() {
			Vaks_Scripts.headerScripts();
			if ($('.accordion-block').length) {
				Vaks_Scripts.accordion();
			}
			if ($('.multi-accordion').length) {
				Vaks_Scripts.multiAccordion();
			}
			if ($('.tabs-section').length) {
				Vaks_Scripts.tabs();
			}
			if ($('.wpcf7').length) {
				Vaks_Scripts.contactsFormRedirect();
				Vaks_Scripts.formSubmit();
			}
			if ($('.filters-section').length) {
				Vaks_Scripts.filters();
				Vaks_Scripts.multiSelectFilters();
			}
			Vaks_Scripts.callsToCourtFilters();
			Vaks_Scripts.newsSlider();
			if ($('.gallery-section').length) {
				Vaks_Scripts.gallery();
			}
			Vaks_Scripts.docsMore();
			Vaks_Scripts.corruptionFormOpen();
			Vaks_Scripts.sitemap();
			Vaks_Scripts.valid();
		}

	};

	$(window).resize(function() {
		Vaks_Scripts.newsSlider();
	});

	$(document).ready(function() {
		Vaks_Scripts.init();
	});

});